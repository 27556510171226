          <div id="home" class="slider_inner_area slider_height" [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')'}">
            <div class="slider_inner_content align-left" style="padding: 20px;">
              <div class="section_title align-left" style="text-align: left;">
                <h3 style="color: #d4edf4; font-size: 24px; margin-bottom: 10px;">Discover your perfect escape</h3>
                <h1 class="header_tag_style" style="font-size: 62px; max-width: 100%; line-height: 1.2; margin-bottom: 20px; color: white;">{{property?.name}}</h1>
                <p class="paragraph" [innerHTML]="property?.businessDescription" *ngIf="isExpanded === false"></p>
                <p class="paragraph2" [innerHTML]="property?.businessDescription" *ngIf="isExpanded === true"></p>

                <button (click)="toggleContent()"  style="background-color:  var(--primary) !important; color: black; font-size: 1.2rem; padding: 10px 20px; border: none; cursor: pointer;">
                  {{ isExpanded ? 'Know Less' : 'Know More' }}
                </button>

              </div>
            </div>





<div class="direct-booking desktop-vr desktop_view_direct_book">
  <div class="booking-form">
   <h2 style="text-align: center;font-weight: 700;">BOOK YOUR STAY</h2>
   <form action="/book-room" method="post" class="form_style_header" style="padding: 30px;background: white;">
     <div class="row d-flex">
       <div id="autocomplete-container"  class="col-6">
        <label for="email">Check-in</label>
          <input #dpFromDate1 type="text" class="date-input input-box form-control box-input-styl" style="background-color:  var(--secondary) !important;  color:  var(--tertiary) !important;" placeholder="Check-in" name="dpFromDate"
          [value]="formatter.format(fromDate) | date:'EEE, MMM d'"
        (input)="toDate = validateInput(toDate, dpToDate2.value)"
            (input)="fromDate = validateInput(fromDate, dpFromDate1.value)" required
            (click)="datepicker1.toggle()" autocomplete="off">
          <div class="form-group" style="margin-bottom: 0px;">
            <input name="datepicker" class="form-control" ngbDatepicker #datepicker1="ngbDatepicker" style="display: none;" [autoClose]="true" (dateSelect)="onDateSelection($event)" [displayMonths]="1"  (navigate)="onMonthChange($event)"
              [dayTemplate]="t1" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1"
              [minDate]="todayDate">
            <ng-template #t1 let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
               <p> {{ date.day }}</p>
              </span>
            </ng-template>
          </div>
        </div>
        <div id="autocomplete-container"  class="col-6">
          <label for="email">Check-out</label>
          <input #dpToDate2 type="text" class="date-input input-box form-control box-input-styl" style="background-color:  var(--secondary) !important;  color:  var(--tertiary) !important;" placeholder="Check-out" name="dpToDate"
             [value]="formatter.format(toDate) | date:'EEE, MMM d'" (input)="toDate = validateInput(toDate, dpToDate2.value)"
             required (click)="datepicker1.toggle()" autocomplete="off" >
       </div>

      </div>





    <div class="row" style="margin-top: 20px;">
     <div class="form-group col-4">
       <label for="adults" class="form-control-label">Adults</label>
       <input type="number" name="adults"  placeholder="No of Adults"  class="form-control box-input-styl" style="background-color:  var(--secondary) !important;  color:  var(--tertiary) !important;" id="adults" min="1" max="30" [(ngModel)]="adults" (input)="onInputChange()">
     </div>
     <div class="form-group col-4">
       <label for="children" class="form-control-label">Children</label>
       <input type="number" name="children"placeholder="No of Children"  class="form-control box-input-styl" style="background-color:  var(--secondary) !important;  color:  var(--tertiary) !important;" id="children" min="0" max="30" [(ngModel)]="children" (input)="onInputChangeone()">
     </div>
     <div class="form-group col-4">
       <label for="rooms" class="form-control-label">Rooms</label>
       <input type="number" name="rooms"placeholder="No of Rooms"  class="form-control box-input-styl" style="background-color:  var(--secondary) !important;  color:  var(--tertiary) !important;" id="rooms" min="1" max="30" [(ngModel)]="rooms" (input)="onInputChangetwo()">
     </div>
    </div>
     <!-- <button type="submit" class="btn btn-primary" style="width: 100%;">Book Now</button> -->
   </form>
   <div class="form-row">
   </div>
   <!-- <button type="submit" style="background-color: red; color: white; padding: 10px; padding-left: 20px;padding-right: 20px; display: flex;margin:auto;
  justify-content: center; font-size: 2opx;" (click)="navigate()">Book</button> -->
  <button type="submit" class="button" (click)="navigate()">Book Now</button>

 </div>
</div>


            </div>
