import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  property: any;
  viewportScroller: any;
  footerBackgroundImageUrl: string;
  gradientColor: string;

  constructor(
    public router: Router,
    public token: TokenStorage,
    public apiService: ApiService
  ) {
    this.property = new Property();
    this.footerBackgroundImageUrl = '/assets/images/footer-back.png'; // You can update this dynamically
    this.gradientColor = 'rgba(0, 0, 0, 0.7)';
    if (this.token.getProperty() !== null) {
      this.property = this.token.getProperty();
      this.footerBackgroundImageUrl = this.property.imageList[1].url;
    } else {
      this.getProperty();
    }
  }

  ngOnInit(): void {
    if (this.token.getProperty() !== null) {
      this.property = this.token.getProperty();
    } else {
      this.getProperty();
    }

    this.changeTheme(
      this.property.primaryColor,
      this.property.secondaryColor,
      this.property.tertiaryColor
    );
  }

  bookYourStay() {
    window.open("https://bookonepms.com/" + this.token.getProperty().seoFriendlyName + "?BookingEngine=true", '_blank');
  }

  book() {
    window.open("https://bookonepms.com/" + this.token.getProperty().seoFriendlyName + "?BookingEngine=true", '_blank');
  }

  redirectToWhatsApp(phoneNumber: string | undefined) {
    if (phoneNumber) {
      // Remove any non-numeric characters except for the leading plus (+) if present
      const formattedNumber = phoneNumber.replace(/[^0-9+]/g, '');

      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${formattedNumber}`;

      // Open the constructed URL in a new tab
      window.open(whatsappUrl, '_blank');
    } else {
      console.error('Phone number is not available or incorrectly formatted');
    }
  }

  changeTheme(primary: string, secondary: string, tertiary: string) {
    document.documentElement.style.setProperty('--primary', primary);
    document.documentElement.style.setProperty('--secondary', secondary);
    document.documentElement.style.setProperty('--tertiary', tertiary);
    document.documentElement.style.setProperty('--button-primary', tertiary);
    document.documentElement.style.setProperty(
      '--primary-gradient',
      'linear-gradient( 180deg, ' + tertiary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-gradient',
      'linear-gradient( 312deg, ' + primary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-one-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--third-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      response => {
        this.property = response.body;
        this.token.saveProperty(this.property);
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          console.error('Error fetching property details:', error.message);
        }
      }
    );
  }

  scroll_top() {
    document.documentElement.scrollTop = 0;
  }

  scrollToSection(section: string): void {
    // First navigate to the section
    this.router.navigate([], { fragment: section }).then(() => {
      // Then force scroll to the section using ViewportScroller
      this.viewportScroller.scrollToAnchor(section);
    });
  }
}
